import Vue from 'vue';
import $ from  'jquery';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {log, info, error} from './libs/log.js'
import Api from './libs/api.js';
import {Utils as utils}  from './libs/utils.js';
import {UI} from './libs/ui.js';
import '../css/app.css';
import './libs/jquery.js';
import forma from './components/forma.vue';
import inputa from './components/inputa.vue';
import cookie  from './thirdparty/cookie.js';
import upperFirst from 'lodash/upperFirst';


Vue.component('forma', forma);
Vue.component('inputa', inputa);

Api.extra = {
    _token: __settings.token
};



(function ($) {
    $(function () {


        $('body').on("click", '.smiles-list .smile', function () {
            //app.smileEdit = $(this);
            //return false;
        });

        $('body').on("click", '.smiles-wrapper .pagination a', function (e) {

            app.smiles(e);
            return false;
        });

        $(document).on("keydown2", function (e) {
            var active = $('.smiles-wrapper .pagination .active:first');
            var cat = $('.cats-col .collection a.active');

            if(active.length) {


                switch (e.which) {
                    case 37: // left
                        active.prev().find("a").trigger("click");
                        break;

                    case 38: // up
                        cat.prev().trigger("click");
                        break;

                    case 39: // right
                        active.next().find("a").trigger("click");
                        break;

                    case 40: // down

                        cat.next().trigger("click");
                        break;

                    default:

                }
            }
            //e.preventDefault(); // prevent the default action (scroll / move caret)

            return true;

        });


        $(document).on("mousemove", function(event){
            if(event.pageX > 300){
                //if(timerSiteMenu) clearTimeout(timerSiteMenu);
                app.toggleSiteMenu(false);
            }
        });

    }); // end of document ready
})($); // end of jQuery name space



Vue.directive('raw', {
    bind: function (el, binding, vnode, oldVnode) {
        if(binding.value) {
            el.innerHTML = binding.value;
        }
    },

    update: function(el, binding, vnode, oldVnode){
        if(typeof binding.value !== "undefined" && binding.value !== binding.oldValue) {
            el.innerHTML = binding.value;
        }
    }
});


/*
Vue.mixin({
  created: function () {
      if(this.$attrs.tags){
          log(this.$attrs.tags);
      }
  }
})
*/



const app = new Vue({
  el: '#app',


  data: {
      smilesLoading: false,
      menuOpen: false,
      navFixed: true,
      cat: null,
      header: undefined,
      smilesList: undefined,
      smilesEditMode: cookie.get('edit_mode'),
      smileEdit: null
  },
  watch: {
      smilesEditMode: function(res){
          //this.appClass.smiles_edit_mode = res;
          //utils.storage("smiles_edit_mode", res);
          if(res){
              cookie.set('edit_mode', 1);
          }else{
              cookie.remove('edit_mode');
          }


          this.smiles();

      }
  },

  created: function(){

  },


  computed: {
      appClass: function(){
          let classes = {"smiles-edit-mode": this.smilesEditMode, 'app-site-menu-fix-open': this.menuOpen, 'nav-fixed': this.navFixed};

          return classes;

      }
  },


  methods: {
      smiles: function(event){

          let el, href;

          if(location.pathname === "/") return;

          if(event) {
              event.preventDefault();
              el = $(event.currentTarget);
              href = el.attr("href");
          }else{
              href = location.href;
          }

          if(!href || href == "#!" || this.smilesLoading) return false;

          let api = new Api();

          this.smilesLoading = true;
          //this.smilesList = "";
          api.raw(href, {editMode: this.smilesEditMode}).then((res, result)=>{


              //this.smilesList = result;
              result.mount("#smiles-output");
              window.location = "#top";
              if(el && el.is(".smile-category")) {
                  this.header = el.text();
                  this.cat = {
                      id: el.data("id"),
                      name: el.text()
                  };
              }


          }).always(() => {
              this.smilesLoading = false;
          });

      },

      toggleSiteMenu: function(flg){

          this.menuOpen = typeof flg !== "undefined" ? flg : !this.menuOpen;
      }
  }


});
