function _log(type){

    if(typeof __debug !== "undefined" && !__debug) return false;

    var args = [].slice.call(arguments);
    //console.log(args);

    if(typeof console !== 'undefined'){
        type = type || "debug";
        if(type in console) {
            console[type].apply(console, args[1]);
        }
    }
};



const log = function(){
    _log("log", arguments);
};

const info =  function(){
    _log("warn", arguments);
};

const error = function(){
    _log("error", arguments);
};

export {log, info, error};