var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "forma",
      class: _vm.classes,
      attrs: { action: _vm.action },
      on: {
        submit: function($event) {
          return _vm.submit($event)
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isloading,
              expression: "isloading"
            }
          ],
          staticClass: "loading-trigger"
        },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.error,
              expression: "error"
            }
          ],
          staticClass: "alert alert-danger"
        },
        _vm._l(_vm.error, function(err) {
          return _c("div", [
            _c("strong", [_vm._v("☠ "), _c("span", [_vm._v(_vm._s(err))])])
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.success,
              expression: "success"
            }
          ],
          staticClass: "alert alert-success"
        },
        [_vm._v("\n        ✔ "), _c("span", [_vm._v(_vm._s(_vm.success))])]
      ),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.submitButton != "false"
        ? _vm._t("submit", [
            _c("div", { staticClass: "form-group text-right" }, [
              _c(
                "button",
                { staticClass: "btn btn-secondary", attrs: { type: "submit" } },
                [_vm._v(_vm._s(_vm.submitButton || "Отправить"))]
              )
            ])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "mt-3 spinner-border text-primary",
        attrs: { role: "status" }
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }