<template>
    <input
            v-on:input="input($event)"
            v-on:keydown="keydown($event)"
            v-on:blur="blur($event)"
            v-on:focus="focus($event)"
            :autocomplete="this.tags ? 'off' : ''"
    />
</template>


<style>
    form{

    }
</style>

<script>

    import Autocomplete from '../components/autocomplete.vue';
    import Api from '../libs/api.js';
    import {log, info, error} from '../libs/log.js';
    import Vue from 'vue';
    import $ from  'jquery';



    export default  {

        data: function(){
            return {

            }
        },

        props: ['submitOnBlur', 'caretOnFocus'],

        created: function(){

            this.autocompleteTimer = null;
            this.autocomplete = null;
        },

        mounted: function(){
            this.initialValue = this.$el.value;


            if(this.tags) {
                let pos = this.positions();
                let ComponentClass = Vue.extend(Autocomplete);
                let autocomplete = new ComponentClass({
                    propsData: {
                        url: "tags/" + this.tags,
                        x: pos.x,
                        y: pos.y
                    }
                });

                //autocomplete.show();
                this.autocomplete = autocomplete;

                this.autocomplete.$on('selected', (item)=>{
                    let m = this.getTag();
                    if(m) {
                        let val = this.$el.value;
                        this.$el.value = val.substring(0, m.index) + (m[1] ? ", " : "") + item.name + ", ";
                        this.$el.focus();
                        this.$el.selectionStart = this.$el.value.length;
                        this.$el.selectionEnd = this.$el.value.length;
                    }
                    this.autocomplete.close()



                });

                this.autocomplete.$on('close', (item)=>{
                    if(this.autocomplete.isSelected()){
                        this.$el.focus();
                    }
                })
            }


        },


        computed: {
            tags: function(){
                return this.$attrs.tags;
            },
            x: function(){

            }
        },

        methods: {

            keydown: function(event){
                let charCode = (event.which) ? event.which : event.keyCode;

                if(charCode === 27 && this.autocomplete){
                    this.autocomplete.close();
                }

                if((charCode === 40 || charCode === 38) && this.autocomplete){
                    this.autocomplete.select(charCode === 40);
                    event.preventDefault();

                }
            },

            input: function(event){
                let el = $(this.$el);
                if(this.tags){


                    let val = el.val();
                    if (val.length > 1 /*&& ((charCode <= 90 && charCode >= 48) || ([8, 32, 46].indexOf(charCode) !== -1))*/ ) {

                        if (this.autocompleteTimer) {
                            clearTimeout(this.autocompleteTimer);
                            this.autocompleteTimer = null;
                        }

                        this.autocompleteTimer = setTimeout(()=>{
                            let m = this.getTag();
                            let v = m ? m[2] : "";
                            v = v.trim();
                            if(v){
                                let pos = this.positions();
                                this.autocomplete.x = pos.x;
                                this.autocomplete.y = pos.y;
                                this.autocomplete.update(v);
                            }

                        }, 300);


                    }else{
                        event.preventDefault();

                    }

                }
            },

            blur: function(){



                if(this.autocomplete){

                    setTimeout(() => {
                        if(!this.autocomplete.isSelected()) {
                            this.autocomplete.close();
                        }
                    }, 1);

                }


                if(typeof this.submitOnBlur !== "undefined"){
                    setTimeout(() => {
                        if(!this.autocomplete || !this.autocomplete.isSelected() && this.initialValue !== this.$el.value) {
                            this.$parent.submit();
                        }
                    }, 1);

                    //log(this);
                    //let form = $(this.$el).parents("form");
                    //if(form.length > 0) form.
                }

            },

            focus: function(){
                if(typeof this.caretOnFocus !== "undefined"){
                    $(this.$el).caretToEnd();
                }

            },

            getTag: function(val){
                if(!val){
                    val = $(this.$el).val();
                }
                return val.match(/(,|^)([^,]+)$/);
            },

            positions: function(){
                let el = $(this.$el);
                let pos = el.offset();
                return {
                    x: pos.left,
                    y: pos.top + el.outerHeight()
                }
            }
        }

    }
</script>
