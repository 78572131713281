import {log, info, error} from '../libs/log.js';
import $ from  'jquery';
import Vue from 'vue';
const EventEmitter = require('events');


class Api {

    constructor() {
        this.responseType = "raw";
        this.withCredentials = false;
    }

    request(url, data, method = "get") {

        url = !/^http/.test(url) && !/^\/api\//.test(url) ? "/api/" + url : url;
        this.responseType = "json";
        this.withCredentials = true;
        return this.raw(url, data, method);


    }

    action(url, data) {
        return this.request(url, data, "post")
    }


    raw(url, data, method = "get") {

        let defered = new $.Deferred();

        if (Api.baseUrl && !/^http/.test(url)) {
            url = Api.baseUrl + url;
        }

        let extra = $.extend({}, Api.extra);


        if (extra) {
            if (!data) data = {};
            for (let prop in extra) {
                if (extra.hasOwnProperty(prop)) {
                    if (data instanceof FormData) {
                        data.append(prop, extra[prop]);
                    } else {
                        data[prop] = extra[prop];
                    }

                }
            }
        }

        if (method === "post") {
            this._addToken(data);
        }


        if (data instanceof FormData && FormData && FormData.prototype._asNative) {
            //ie must die
        }

        let options = {
            url: url,
            data: data,
            method: method,
            processData: data instanceof FormData ? false : true,
            contentType: data instanceof FormData ? false : "application/x-www-form-urlencoded; charset=UTF-8",
            //dataType: "",
        };


        if(this.withCredentials) {
            options.xhrFields = {
                withCredentials: true
            }
        }

        if (Api.token || method !== "post") {
            this._ajax(options, defered);
        } else {
            this.request("session").then((res) => {
                this._addToken(data);
                this._ajax(options, defered);
            }).catch((err, result) => {
                defered.reject(err, result);
            })
        }


        return defered;
    }


    _addToken(data, token) {
        if (!token) token = Api.token;
        let key = "_token";

        if (data instanceof FormData) {
            data.delete(key);
            data.append(key, token);
        } else {
            data[key] = token;
        }
    }

    _ajax(extra, defered) {
        let options = {

            beforeSend: function () {

            },

            success: function () {

            },
            error: function () {

            },

            complete: (xhr, textStatus) => {

                log(xhr);

                let result = new ApiResult(this.responseType);
                result.xhr(xhr);

                if (result.meta.tokenExpire || result.meta.tokenReset) {
                    Api.token = null;
                }

                if (result.meta.token) {
                    Api.token = result.meta.token;
                }

                if (result.error) {
                    //UI.toast(result.error).error();
                    defered.reject(result.error, result);

                } else {
                    defered.resolve(result.result, result);
                }

                Api.listener.emit("request", result);
            },

        };

        let merged = $.extend(options, extra);

        $.ajax(merged);

    }


    static opt(name, value) {
        Api.config[name] = value;
    }

}


Api.listener = new EventEmitter();

class ApiResult {

    constructor(responseType) {

        this.result = null;
        this.raw = null;
        this.json = null;
        this.message = null;
        this.responseType = responseType;
        this.meta = {};


        //vnode.$mount();
        //this.result = $('<div></div>').append(vnode.$el).html();

    }

    setError(err) {
        this.error = err;
    }

    xhr(xhr) {

        this.result = xhr.responseText;
        this.raw = xhr.responseText;

        if (xhr.status !== 200) {
            this.setError(xhr.statusText);
            if (xhr.status === 404) {
                this.setError("Не найдено");
            }

            if (xhr.status === 500) {
                this.setError("Внутренняя ошибка");
            }
        }

        if (this.responseType === "json") {
            //try parse json
            try {
                this.json = JSON.parse(this.raw);
            } catch (e) {
                error(this.raw, e);
                this.setError("Ошибка парсинга");
            }
        }

        if (this.json) {
            this.result = this.json.res || {};
            this.message = this.json.message || "";
            this.meta = this.json.meta || {};
            if (this.json.error) {
                this.setError(this.json.error);
            }
        }
    }

    mount(el) {

        new Vue({
            el: el,
            template: this.result
        });
    }
}


Api.extra = {};
Api.baseUrl = "";
Api.token = "";


export default Api;
