import $ from 'jquery';
import {log, info, error} from './log.js'


$.fn.extend({
        insertAtCaret: function(myValue){
            return this.each(function(i) {
                    if (document.selection) {
                        //For browsers like Internet Explorer
                        this.focus();
                        let sel = document.selection.createRange();
                        sel.text = myValue;
                        this.focus();
                    }
                    else if (this.selectionStart || this.selectionStart == '0') {

                        //For browsers like Firefox and Webkit based
                        let startPos = this.selectionStart;

                        let endPos = this.selectionEnd;
                        let scrollTop = this.scrollTop;
                        this.value = this.value.substring(0, startPos)+myValue+this.value.substring(endPos,this.value.length);
                        this.focus();
                        this.selectionStart = startPos + myValue.length;
                        this.selectionEnd = startPos + myValue.length;
                        this.scrollTop = scrollTop;
                        //console.log(this.selectionStart, this.selectionEnd);
                    } else {
                        this.value += myValue;
                        this.focus();
                    }
            });
        },

        caretToEnd: function(myValue){
            return this.each(function(i) {
                    if (document.selection) {
                        //For browsers like Internet Explorer
                        this.focus();
                        let sel = document.selection.createRange();
                        this.focus();
                    }
                    else if (this.selectionStart || this.selectionStart == '0') {

                        //For browsers like Firefox and Webkit based
                        let startPos = this.selectionStart;
                        let endPos = this.selectionEnd;
                        let scrollTop = this.scrollTop;
                        //this.value = this.value.substring(0, startPos)+myValue+this.value.substring(endPos,this.value.length);
                        this.focus();
                        this.selectionStart = startPos + this.value.length;
                        this.selectionEnd = startPos + this.value.length;
                        this.scrollTop = scrollTop;
                    } else {

                        this.focus();
                    }
            });
        },

});

$.fn.pparent = function(selector){
    return this.parents(selector + ":first");
};

$.fn.infiniteScroll = function(opt){


    let scrollTopLast;
    let lastCheck;
    this.on("scroll", function(){
        let $this = $(this);
        let scrollTop = $this.scrollTop();
        let isUp = !(scrollTop >= scrollTopLast) && $this.scrollBottom();

        scrollTopLast = scrollTop;

        if(!isUp && $this.isOverflow() && $this.iScrollEndY(opt.distance)){
            if(!lastCheck) {
                lastCheck = true;
                if (typeof opt.callback === "function") {
                    opt.callback();
                }
            }
        }else{
            //error("lastCheck false");
            lastCheck = false;
        }
    });
};

$.fn.isOverflow = function(){
    let e = this[0];
    return e.scrollHeight > e.clientHeight || e.scrollWidth > e.clientWidth;
};

$.fn.iScrollEnd = function(){
    let e = this[0];
    return this.iScrollEndX() && this.iScrollEndY();
};

$.fn.iScrollEndY = function(distance){
    let e = this[0];

    let diff = (e.scrollHeight - (e.clientHeight + e.scrollTop));


    if(!distance){
        return diff === 0;
    }else{
        return diff <= distance;
    }
};

$.fn.iScrollEndX = function(){
    let e = this[0];
    return (e.scrollWidth - (e.clientWidth + e.scrollLeft)) === 0;
};

$.fn.scrollBottom = function(){
    let e = this[0];
    return e.scrollHeight - (e.clientHeight + e.scrollTop);
};