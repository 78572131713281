<template>
    <form v-on:submit="submit($event)" v-bind:action="action" :class="classes" class="forma">

        <div class="loading-trigger" v-show="isloading">
            <div class="mt-3 spinner-border text-primary" role="status" >
              <span class="sr-only">Loading...</span>
            </div>
        </div>

        <div class="alert alert-danger" v-show="error">
            <div v-for="err in error"><strong>&#9760; <span>{{err}}</span></strong></div>
        </div>
        <div class="alert alert-success" v-show="success" >
            &#10004; <span>{{success}}</span>
        </div>

        <slot></slot>


        <slot name="submit" v-if="submitButton != 'false'">
            <div class="form-group text-right">
                <button type="submit" class="btn btn-secondary">{{submitButton || "Отправить"}}</button>
            </div>
        </slot>

    </form>
</template>

<style>
    form {

    }

    .forma.loading .loading-trigger{
        position: absolute;
        left: 50%;
        top:50%;
        z-index: 2;
        transform: translate(-50%, -50%);
    }

    .forma.loading:after{
        content: "";
        display: block;
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: #000;
        opacity: 0.6;
    }

</style>

<script>

    import Api from '../libs/api.js';
    import {log, info, error} from '../libs/log.js';
    import $ from  'jquery';

    export default  {
        name: 'forma',
        props: {
            action: {
                type: String
            },
            method: {
                type: String
            },
            submitButton: {
                type: String
            }
        },

        data: function () {
            return {
                error: false,
                success: false,
                isloading: false,
                formData: {}
            }
        },

        created: function () {

            this.before = null;

        },



        computed: {
            submitBtn: function () {
                return !!this.$slots.submit
            },
            classes: function(){
                return {
                    "loading": this.isloading
                };
            }
        },

        methods: {

            beforePromise: function(form){
                let defered = new $.Deferred();
                if(typeof this.before === "function"){
                    this.before(form).then(()=>{
                        defered.resolve();
                    })
                }else{
                    defered.resolve();
                }
                return defered;
            },

            reset: function(){
                this.success = false;
                this.error = false;
                this.$el.reset();
            },

            submit: function (event) {
                this.isloading = true;
                let form = this.$el;
                if (event) {
                    event.preventDefault();
                }

                this.formData = new FormData(form);

                this.beforePromise(this.formData).then(()=>{
                    let api = new Api();
                    api.action(this.action, this.formData).then((res, response) => {
                        this.error = false;
                        this.success = response.message;
                        this.$emit("success", res, response);
                    }).catch((err) => {

                        let errors = [];
                        err.split("\n").map((item)=>{
                            errors.push(item);
                        });

                        this.error = errors;
                        this.success = false;
                        this.$emit("fail", err);
                    }).always(()=>{
                        this.isloading = false;
                    });
                }).always(()=>{
                    this.isloading = false;
                });


            }
        }

    }
</script>
