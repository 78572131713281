<template>
    <ul
            class="autocomplete list-group"
            v-on:keydown="keydown($event)"
            v-show="open"
            v-bind:id="id"
            v-bind:style="{ left: this.x + 'px', top: this.y + 'px'}"
    >
        <button
                tabindex="-1"
                :class="{'list-group-item list-group-item-action': true, 'active': scrollIndex === index}"
                v-for="(item, index) in items"
                v-on:click="click(item)"
                :data-index="index"
                ref="item"
            >

           {{ item.name }}

        </button>

        <div class="spinner-grow text-success spinner-grow-sm" v-show="loading" role="status">
          <span class="sr-only">Loading...</span>
        </div>



    </ul>
</template>

<style>

    .autocomplete{
        position: absolute;
        z-index: 1;
    }

    .automplete > li{

    }
</style>

<script>
    import {log, info, error} from '../libs/log.js';
    import {Utils} from '../libs/utils.js';
    import Api from '../libs/api.js';
    import $ from  'jquery';
    export default {

        name: "automplete",
        props: ["id", "url", "x", "y"],

        data: function(){
            return {
                items: [],
                open: false,
                loading: false,
                scrollIndex: null
            }
        },

        methods: {
            position: function(x, y){
                //$('#' + this.id).toast({delay: 5000}).toast("show");
            },
            update: function(search){
                let api = new Api;
                this.loading = true;
                this.items = [];
                api.request(this.url, {search: search}).then((res, result)=>{

                    this.scrollIndex = null;
                    this.items = res;
                    this.open = true;
                }).always(()=>{
                    this.loading = false;
                });
            },

            close: function(){
                this.$emit("close");
                this.open = false;
            },

            click: function(item){

                this.$emit('selected', item);
            },

            select: function(){
                if(this.$refs.item && this.$refs.item.length > 0) {
                    this.scrollIndex = 0;
                    this.$refs.item[this.scrollIndex].focus();
                }

            },

            keydown: function(event){
                let charCode = (event.which) ? event.which : event.keyCode;
                if((charCode === 40 || charCode === 38)){
                    let down = charCode === 40;

                    var items = this.$refs.item;

                    if(down) {
                        this.scrollIndex++;
                        if(!items[this.scrollIndex]){
                            this.scrollIndex = 0;
                        }
                    }else{
                        this.scrollIndex--;
                        if(!items[this.scrollIndex]){
                            this.scrollIndex = items.length - 1;
                        }
                    }

                    items[this.scrollIndex].focus();

                    event.preventDefault();

                    //this.autocomplete.scroll(charCode === 40)
                }

                if(charCode === 27){
                    this.close();
                }

            },
            isSelected: function(){

                return document.activeElement && $(document.activeElement).parents().is(this.$el);
            }
        },

        created: function(){
            if(!this.id) this.id = Utils.makeid(10);
            this.$mount();
            $("body").append(this.$el);
        },

    }
</script>