var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.open, expression: "open" }
      ],
      staticClass: "autocomplete list-group",
      style: { left: this.x + "px", top: this.y + "px" },
      attrs: { id: _vm.id },
      on: {
        keydown: function($event) {
          return _vm.keydown($event)
        }
      }
    },
    [
      _vm._l(_vm.items, function(item, index) {
        return _c(
          "button",
          {
            ref: "item",
            refInFor: true,
            class: {
              "list-group-item list-group-item-action": true,
              active: _vm.scrollIndex === index
            },
            attrs: { tabindex: "-1", "data-index": index },
            on: {
              click: function($event) {
                return _vm.click(item)
              }
            }
          },
          [_vm._v("\n\n       " + _vm._s(item.name) + "\n\n    ")]
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "spinner-grow text-success spinner-grow-sm",
          attrs: { role: "status" }
        },
        [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }