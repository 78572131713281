var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    attrs: { autocomplete: this.tags ? "off" : "" },
    on: {
      input: function($event) {
        return _vm.input($event)
      },
      keydown: function($event) {
        return _vm.keydown($event)
      },
      blur: function($event) {
        return _vm.blur($event)
      },
      focus: function($event) {
        return _vm.focus($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }