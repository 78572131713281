<template>
    <div class="toast" v-bind:data-type="type" v-bind:id="id" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
            <strong class="mr-auto">{{header || (type === "error"  ? "Ошибка" : "Сообщение")}}</strong>
            <small>{{desc}}</small>
            <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="toast-body">
            {{body}}
        </div>
    </div>
</template>

<style>
    .toast{
        position: fixed;
        bottom:0;
        left:0;
    }

    .toast[data-type="error"] .toast-header{
        color: red
    }

</style>

<script>
    import {log, info, error} from '../libs/log.js';
    import {Utils} from '../libs/utils.js';
    export default {

        name: "toast",
        props: ["id", "body", "header", "desc", "type"],
        methods: {
            show: function(){
                log(this);
                this.$mount();
                $("body").append(this.$el);
                $('#' + this.id).toast({delay: 5000}).toast("show");
            }
        },

        created: function(){
            if(!this.id) this.id = Utils.makeid(10);
        },

        data: function(){

            return {

            };
        }
    }
</script>