import {log, info, error} from '../libs/log.js';
import toast from '../components/toast.vue';
import Vue from 'vue';

class UI{



    static toast(){
        return this.make(Toast, arguments);
    }

    static make(Class, a) {
        let args = [].slice.call(a);
        args.unshift(null);
        return new (Function.prototype.bind.apply(Class, args));
    }

}


class Toast{


    constructor(text, config){


        if(!config) config = {};
        this.config = $.extend({
            body: text
        }, config);
    }

    make(){

        let ComponentClass = Vue.extend(toast);
        let instance = new ComponentClass({
            propsData: this.config
        });

        instance.show();
    }

    error(){
        this.config.type = "error";
        this.make();
    }

    alert(){
        this.config.type = "alert";
        this.make();
    }


}


export {UI};

