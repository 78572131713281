var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "toast",
      attrs: {
        "data-type": _vm.type,
        id: _vm.id,
        role: "alert",
        "aria-live": "assertive",
        "aria-atomic": "true"
      }
    },
    [
      _c("div", { staticClass: "toast-header" }, [
        _c("strong", { staticClass: "mr-auto" }, [
          _vm._v(
            _vm._s(
              _vm.header || (_vm.type === "error" ? "Ошибка" : "Сообщение")
            )
          )
        ]),
        _vm._v(" "),
        _c("small", [_vm._v(_vm._s(_vm.desc))]),
        _vm._v(" "),
        _vm._m(0)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "toast-body" }, [
        _vm._v("\n        " + _vm._s(_vm.body) + "\n    ")
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "ml-2 mb-1 close",
        attrs: {
          type: "button",
          "data-dismiss": "toast",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }