class Utils {

    static  firstToUpper(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static  firstToLower(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    static  storage(key, val) {
        let res = "";
        if ('localStorage' in window && window['localStorage']) {
            res = typeof val === "undefined" ? localStorage.getItem(key) : localStorage.setItem(key, val);
        }

        if (res === "true") res = true;
        if (res === "false") res = false;

        return res;
    }

    static storageRemove(key) {
        if ('localStorage' in window && window['localStorage']) {
            localStorage.removeItem(key);
        }
    }

    static storagej(key, val) {

        let res = null;

        if (typeof val === "undefined") {
            let text = this.storage(key);
            if (text) {
                try {
                    res = JSON.parse(text);
                } catch (e) {
                    error(e);
                }
            }
        } else {
            try {
                this.storage(key, JSON.stringify(val));
            } catch (e) {

            }
        }

        return res;

    }


    static makeid(len) {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < len; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    static parseEnv(str) {
        let arr = str.toString().split("\n");
        let res = {};
        arr.forEach(function (line) {
            let a = line.split("=");
            let n = a[0], v = a[1];
            if (n) {
                res[n.trim()] = v ? v.trim() : "";
            }
        });

        return res;
    }

    static isObject(item) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }

    static merge(target, ...sources) {
        if (!sources.length) return target;
        const source = sources.shift();

        if (this.isObject(target) && this.isObject(source)) {
            for (const key in source) {
                if (this.isObject(source[key])) {
                    if (!target[key]) {
                        Object.assign(target, {[key]: {}});
                    } else {
                        target[key] = Object.assign({}, target[key])
                    }
                    this.merge(target[key], source[key]);
                } else {
                    Object.assign(target, {[key]: source[key]});
                }
            }
        }

        return this.merge(target, ...sources);
    }

    static array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            let k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

}


export {Utils};